import React from 'react'
import Layout from '../components/layout/Layout'
import MetaData from '../components/seo/MetaData'
import Container from '../components/layout/Container'
import { Data } from '../models/StoryblokModel'
import StoryBlokImageComponent from '../components/images/imagecomponents/StoryBlokImageComponent'

interface Props {    
  data : Data;
  pageContext: PageContext;
  location: any;
}

interface PageContext{
  title: string;
  headerImage: string;
  article: any;
}



const ArticleTemplate : React.FunctionComponent<Props> = ({ pageContext }) => {
  return(
  <Layout>
    <MetaData 
        title={pageContext.title}
        description={`Læs mere om vores artikel.`}
    />
    <div className={`container mx-auto px-4 grid grid-cols-12 gap-4`}>
      <div className="col-span-8">
        <h1 className="mt-8 mb-8">{pageContext.title}</h1>
        <StoryBlokImageComponent src={pageContext.headerImage} alt={pageContext.title} dimensions="1200x900" />
        <div dangerouslySetInnerHTML={{__html:pageContext.article}}></div>
      </div>
      <div className="col-span-4">
         
      </div>
    </div>
  </Layout>
  )
}

export default ArticleTemplate